import gql from "graphql-tag";
import { TABLE } from "../main";

export const UPDATE_PERSON_HAIR_SEGMENTATION = gql`
  mutation MyMutation(
    $id: Int!,
    $hair_segmentation: String!
  ) {
    update_arm_hair_segmentation(
      where: { id: { _eq: $id } }
      _set: { hair_segmentation: $hair_segmentation, isLabelled: "Labelled" }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_PERSON_ARM_SEGMENTATION = gql`
  mutation MyMutation(
    $id: Int!,
    $hair_segmentation: String!
  ) {
    update_torso_arm_correction(
      where: { id: { _eq: $id } }
      _set: { torso_arm_segmentation: $hair_segmentation, isLabelled: "Labelled" }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_PERSON_RIGHT_ARM_SEGMENTATION = gql`
  mutation MyMutation(
    $id: Int!,
    $right_arm_segmentation: String!
  ) {
    update_torso_arm_correction(
      where: { id: { _eq: $id } }
      _set: { right_arm_segmentation: $right_arm_segmentation, isLabelled: "Labelled" }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_PERSON_LEFT_ARM_SEGMENTATION = gql`
  mutation MyMutation(
    $id: Int!,
    $left_arm_segmentation: String!
  ) {
    update_torso_arm_correction(
      where: { id: { _eq: $id } }
      _set: { left_arm_segmentation: $left_arm_segmentation, isLabelled: "Labelled" }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_PERSON_BACKGROUND_SEGMENTATION = gql`
  mutation MyMutation(
    $id: Int!,
    $background_segmentation: String!
  ) {
    update_torso_arm_correction(
      where: { id: { _eq: $id } }
      _set: { background_segmentation: $background_segmentation, isLabelled: "Labelled" }
    ) {
      affected_rows
    }
  }
`;

// export const UPDATE_PERSON_RIGHT_BARE_ARM_SEGMENTATION = gql`
//   mutation MyMutation(
//     $id: Int!,
//     $right_bare_tucked_in_segmentation: String!
//   ) {
//     update_tucked_in_segmentation(
//       where: { id: { _eq: $id } }
//       _set: { right_bare_tucked_in_segmentation: $right_bare_tucked_in_segmentation, isLabelled: "Labelled" }
//     ) {
//       affected_rows
//     }
//   }
// `;

// export const UPDATE_PERSON_LEFT_BARE_ARM_SEGMENTATION = gql`
//   mutation MyMutation(
//     $id: Int!,
//     $left_bare_tucked_in_segmentation: String!
//   ) {
//     update_tucked_in_segmentation(
//       where: { id: { _eq: $id } }
//       _set: { left_bare_tucked_in_segmentation: $left_bare_tucked_in_segmentation, isLabelled: "Labelled" }
//     ) {
//       affected_rows
//     }
//   }
// `;
export const GET_PERSON_IMAGE = gql`
  query MyQuery($id: Int!) {
    torso_arm_correction(
      where: { id: { _eq: $id }}
    ) {
      torso_arm_segmentation
      left_arm_segmentation
      right_arm_segmentation
      background_segmentation
      isLabelled
      is_pred_good
        map_related_cloth{
          map_cloth{
            image_url
          }
        }
        map_related_cloth_rm{
          map_cloth{
            image_url
          }
        }
    }
  }
`;

export const GET_GRAPY_SEGMENTATION = gql`
query MyQuery($id: Int!) {
  grapy_segmentation(where: {person_id: {_eq: 10}}) {
    grapy_segmentation
  }
}
`;

export const UPDATE_APPROPRIATE = gql`mutation MyMutation($id: Int, $appropriate: String) {
  update_tucked_in_segmentation(where: {id: {_eq: $id}}, _set: {appropriate: $appropriate,isLabelled: "Labelled"}) {
    affected_rows
  }
}`;

export const UPDATE_OVERLAP_PRESENT = gql`mutation MyMutation($id: Int, $overlap_present: String) {
  update_torso_segmentation(where: {id: {_eq: $id}}, _set: {overlap_present: $overlap_present, isLabelled: "Labelled"}) {
    affected_rows
  }
}`;

export const UPDATE_PRED_GOOD = gql`mutation MyMutation($id: Int, $is_perfect: String) {
  update_${TABLE}(where: {id: {_eq: $id}}, _set: {is_perfect: $is_perfect, isLabelled: "Labelled"}) {
    affected_rows
  }
}`;

export const UPDATE_DIFICULTY = gql`mutation MyMutation($id: Int, $dificulty: Int) {
  update_tucked_in_segmentation(where: {id: {_eq: $id}}, _set: {difficulty: $dificulty, isLabelled: "Labelled"}) {
    affected_rows
  }
}`;

export const UPDATE_ARM_POSITION = gql`
mutation MyMutation($id: Int, $left_arm_position: String, $right_arm_position: String) {
  update_tucked_in_segmentation(where: {id: {_eq: $id}}, _set: {left_arm_position: $left_arm_position, right_arm_position: $right_arm_position}) {
    affected_rows
  }
}
`;