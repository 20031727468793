import React, { useEffect, useState } from "react";
import { Input, FormControl, FormLabel, Button } from "@chakra-ui/core";
import { GET_PRODUCT_ID_HELPER } from "../api/home";
import { useQuery } from "@apollo/react-hooks";
import {GET_UNLABELLED_IDS, TABLE} from "../main";

function Helper(props) {
  const [startId, setStartId] = useState();
  const [num, setNum] = useState();
  const [findId, setFindId] = useState(false);
  const [lastId, setLastId] = useState();
  const [len, setLen] = useState();

  // const { data: result, loading } = useQuery(GET_PRODUCT_ID_HELPER, {
  //   skip: !findId,
  //   variables: {
  //     current: parseInt(startId),
  //     limit: parseInt(num),
  //   },
  //   onCompleted(data) {
  //     if (data) {
  //       setLen(data["product_condition"].length);
  //       setLastId(parseInt(data["product_condition"][data["product_condition"].length - 1]["product_id"]));
  //       setFindId(false);
  //     }
  //   },
  // });

  const {data: ids, loadingIds} = useQuery(GET_UNLABELLED_IDS,{
    skip: (!startId && !num && !findId),
    onCompleted(data){
      console.log(data)
    },
    variables: {
      startId: startId,
      endId: num
    }
  })


  const onInputChange = (e) => {
    if (e.target.name == "num") {
      setNum(e.target.value);
    }
    if (e.target.name == "startId") {
      setStartId(e.target.value);
    }
  };

  console.log(ids)

  const submit = () => {
    setFindId(true);
  };

  useEffect(()=> {
    if(loadingIds==false){
      setFindId(false)
    }
  }, [loadingIds])

  return (
    <div>
      <h3>Unlabelled ids</h3>
      <form style={{ width: "200px", margin: "auto" }}>
        <FormControl>
          <FormLabel htmlFor="startId">Start Id</FormLabel>
          <Input name="startId" value={startId} onChange={onInputChange} />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="num">End Id</FormLabel>
          <Input
            name="num"
            placeholder=""
            value={num}
            onChange={onInputChange}
          />
        </FormControl>
        {/* <Button onClick={submit}>FIND</Button> */}
        {/* {lastId && <div>The last Id is {lastId}</div>}
        {len && <div>Number Of Products: {len}</div>} */}
      </form>
      {loadingIds && <div>loading</div>}
        {/* {<div>{ids}</div>} */}
        {ids && ids[TABLE + '_aggregate'] && <div> Count : <b>{ids[TABLE + '_aggregate']['aggregate']['count']}</b></div>}
        <div style={{display: "flex", width: '80%', margin: 'auto', flexWrap: 'wrap'}}>
        {ids && ids[TABLE + '_aggregate'] && ids[TABLE + '_aggregate']['nodes'].map((id)=> (<div style={{margin: '10px 20px'}}>{id.id}</div>))}
        </div>
    </div>
  );
}

export default Helper;
