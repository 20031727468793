import React, { useState, useEffect } from "react";
import Home from "./home";
import {
  GET_PRODUCT_IDS_CONDITION,
  CHECK_PRODUCT_ID_CONDITION,
  GET_ALL_IMAGES,
} from "../api/home";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import gql from "graphql-tag";
import {GET_IMAGE_URL, TABLE, WORST_CASE_QUERY} from "../main";
import moment from "moment";

export const UPDATE_TIME = gql`
  mutation MyMutation(
    $id: Int!,
    $time: String!
  ) {
    update_tops_cloth_5_part_review(
      where: { id: { _eq: $id } }
      _set: {
        last_visited: $time
        }
    ) {
      affected_rows
    }
  }
`;



function App(props) {

  const history = useHistory();

  const { data: data, loading: loading } = useQuery(GET_IMAGE_URL, {
    variables: {
      id: parseInt(props.match.params.imageid)
    }
  });

  const getWorstCaseDate = (query) => {
    if(query){
      query = query.split("?")
      if(query.length > 0){
        query = query[1];
        query = query.split("=")
        if(query.length > 0){
          return query[1];
        }
      }
    }
  }

  const { data: worstCaseData, loading: worstCaseLoading } = useQuery(WORST_CASE_QUERY, {
    variables: {
      id: parseInt(props.match.params.imageid),
      worst_case_date: getWorstCaseDate(props.location.search)
    }
  });

  useEffect(() => {
    console.log(props)
    if(worstCaseData && worstCaseData[TABLE].length > 0 && props && props.location.search.includes("worstcase")){
      let flag = false;
      console.log(worstCaseData, "worstcase")
      for(let i = 0;i<worstCaseData[TABLE].length;i++) {
        if(worstCaseData[TABLE][i].id==props.match.params.imageid){
          flag = true;
        }
      }
      if(!flag){
        history.push(`/name=${props.match.params.name}/imageid=${worstCaseData[TABLE][0].id}${props.location.search}`)
      }
    }
  }, [worstCaseData])
  // const [update_data, { error: formSubmitError, loading: formLoading }] = useMutation(UPDATE_SEGMENTATION, {
  //   onError(error) {
  //     alert(`The form did not submit. Please check your internet connection. \n For Technical Purpose: ${error}`)
  //   },
  //   onCompleted(data) {
  //     if (data) {
  //       console.log("updated...")
  //       let nextid = parseInt(props.match.params.imageid) + 1;
  //       history.push(`/imageid=${nextid}`)
  //       window.location.reload()
  //     }
  //   }
  // });

  const [update_time] = useMutation(UPDATE_TIME)

  const linkChange = (link) => {
    if (link === undefined) {
      return undefined;
    } else {
      return `https://storage.googleapis.com/download/storage/v1/b/${
        link.split("/")[3]
        }/o/${link
          .substring(34 + link.split("/")[3].length)
          .replace(/[/]/g, "%2F")}?alt=media`;
    }
  };
  const handleNext = async () => {
    await update_time({
      variables: {
        id: parseInt(props.match.params.imageid),
        time: moment().format('LLL')
      }
    })
    let nextid = parseInt(props.match.params.imageid) + 1;
    history.push(`/name=${props.match.params.name}/imageid=${nextid}${props.location.search}`)
  };

  if (loading) {
    return <div>loading</div>;
  }
  if(props.location.search.includes("worstcase") && worstCaseData && worstCaseData[TABLE].length === 0){
    return <div>No worst case ahead left</div>
  }
  return (
    <div>
      <div style={{position: "absolute", right: 0}}>Worst case: {data[TABLE][0]["worst_case_labelled"]} </div>
      <Home person_data={data && data[TABLE][0]} nextImage={handleNext} props={props} />
    </div>
  );
}

export default App;
